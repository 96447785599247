<template>
    <div class="brandfest">
        <headComp></headComp>
        <breadComp></breadComp>

        <div class="centerWrapper">
            <div class="indexes">
                <div class="item all" :class="{'active':activeLetter==''}" @click="clickLetter('')">全部</div>
                <div class="item" :class="{'active':activeLetter==i}" v-for="i in capWord" :key="i" @click="clickLetter(i)">{{i}}</div>
            </div>
            <div class="list">
                <div class="item" v-for="(item,i) in brandList" :key="i" @click="enterBrand(item)">
                    <!-- <img src="../assets/imgs/place35.png"/> -->
                    <div class="cover">
                        <img :src="item.cover?item.cover:require('../assets/placeImgs/place_img3.png')"/>
                    </div>
                    <div class="info">
                        <div class="line1">
                            <!-- <img src="../assets/imgs/place36.png"/> -->
                            <img :src="item.brandLogo?item.brandLogo:(item.cover?item.cover:item.defaultUrl)"/>
                        </div>
                        <div class="line2">
                            {{item.brandName}}
                        </div>
                        <div class="line3" v-if="false">
                            <!-- {{item.brandDescribe}} -->
                            {{item.contactsName}}
                        </div>
                        <div class="line4">
                            <el-button class="outer" round>进入品牌
                                <el-button class="inner" circle icon="el-icon-arrow-right"></el-button>
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="pagi">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-size="pageSize"
                    layout="prev, pager, next, jumper"
                    :total="totalNum">
                </el-pagination>
            </div>
        </div>

        <footerComp></footerComp>
    </div>
</template>

<script>
import headComp from "@/components/headComp.vue"
import footerComp from "@/components/footerComp.vue"
import breadComp from "@/components/breadComp.vue"
import {getBrandPageList} from "@/axios/api"
export default {
    components: {
        headComp,footerComp,breadComp
    },
    data(){
        return{
            currentPage:1,
            pageSize:12,
            word:"abcdefghijklmnopqrstuvwxyz",
            activeLetter:"",
            brandList:[],
            totalNum:0,
        }
    },
    created() {
        // console.log(this.capWord);
        this.gainBranPageList();
    },
    methods: {
        handleSizeChange(val){
            this.pageSize=val;
            this.gainBranPageList();
        },
        handleCurrentChange(val){
            this.currentPage=val;
            this.gainBranPageList();
        },
        // 获取品牌分页列表
        gainBranPageList(){
            getBrandPageList({
                pageNum:this.currentPage,
                pageSize:this.pageSize,
                letter:this.activeLetter
            }).then(res=>{
                // console.log("品牌列表",res);
                if(res.code==200){
                    this.brandList=res.data.rows;
                    this.totalNum=Number(res.data.total);
                }
            })
        },
        // 点击字母
        clickLetter(i){
            this.activeLetter=i;
            this.gainBranPageList();
        },
        // 点击进入品牌
        enterBrand(item){
            // // console.log(item);
            sessionStorage.removeItem('searchKeyword')
            sessionStorage.brandId=item.brandId;
            this.$router.push({
                path:"/merchandise",
                query:{fromBrand:true,pa:"bi_"+item.brandId}
            })
        }
    },
    computed:{
        capWord(){
            return this.word.toUpperCase()
        }
    }
}
</script>

<style lang="less" scoped>
.brandfest{
    .indexes{
        margin-top: 50px;
        display: flex;
        align-items: center;
        .item{
            width: calc(100% / 26);
            height: 40px;
            font-size: 28px;
            font-weight: 400;
            color: rgba(128, 128, 128, 1);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            margin: 0 2px;
            &:hover,&.active{
                background: rgba(225, 242, 236, 1);
                color: rgba(37, 126, 93, 1)
            }

            &.all{
                font-size: 24px;
            }
        }
    }
    .list{
        margin-top: 80px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .item{
            width: 704px;
            // height: 347px;
            height: 450px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 2px 4px  rgba(0, 0, 0, 0.15);
            display: flex;
            margin-bottom: 36px;
            cursor: pointer;
            &:hover{
                .cover{
                    img{
                        // width: 553px;
                        // height: 387px;
                        width: 490px;
                        height: 490px;
                    }
                }
            }
            &>.cover{
                // width: 513px;
                // height: 347px;
                width: 450px;
                height: 450px;
                position: relative;
                overflow: hidden;
                background: rgba(245, 245, 245, 1);
                &>img{
                    // width: 513px;
                    // height: 347px;
                    width: 450px;
                    height: 450px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    transition: all 0.3s;
                    // object-fit: cover;
                    object-fit: contain;
                }
            }
            
            .info{
                flex: 1;
                &>div{
                    text-align: center;
                }
                .line1{
                    // margin-top: 60px;
                    margin-top: 110px;
                    img{
                        width: 116px;
                        height: 58px;
                        object-fit: cover;
                    }
                }
                .line2{
                    margin-top: 25px;
                    font-size: 18px;
                    font-weight: 400;
                    color: rgba(56, 56, 56, 1);
                }
                .line3{
                    margin-top: 15px;
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(166, 166, 166, 1);
                }
                .line4{
                    .outer{
                        margin-top: 60px;
                        height: 45px;
                        background: rgba(255, 255, 255, 1);
                        border-radius: 23px;
                        font-size: 15px;
                        font-weight: 400;
                        color: rgba(56, 56, 56, 1);
                        &:hover{
                            background: rgba(225, 242, 236, 1);
                            color: rgba(37, 126, 93, 1);
                            .inner{
                                background: rgba(225, 242, 236, 1);
                                color: rgba(37, 126, 93, 1);
                                // border-color: rgba(37, 126, 93, 1);
                            }
                        }
                        .inner{
                            height: 20px;
                            width: 20px;
                            padding: 0;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
    .pagi{
        margin-top: 130px;
        text-align: center;
        /deep/.btn-next , /deep/.btn-prev{
            min-width: 46px;
            height: 46px;
            line-height: 46px;
        }
        /deep/ .number , /deep/.el-icon-more, /deep/.btn-quickprev, /deep/.btn-quicknext{
            min-width: 46px;
            height: 46px;
            line-height: 46px;
            &.active{
                background-color: rgba(35, 125, 92, 1)!important;
            }
        }
        /deep/.el-pagination__jump{
            height: 46px;
            line-height: 46px;
            font-size: 16px;
            font-weight: 500;
            columns: rgba(0, 0, 0, 1);
            .el-pagination__editor{
                height: 46px;
                .el-input__inner{
                    height: 46px;
                }
            } 
        }
    }
}
</style>